@font-face {
  font-family: NeueHaasUnica-Regular;
  src: local(NeueHaasUnica-Regular), url("NeueHaasUnica-Regular.c01e5829.woff") format("woff");
}

::selection {
  color: #fff;
  background: #000;
}

body {
  height: 100vh;
  -webkit-font-smoothing: antialiased;
  background: #fff;
  height: 100svh;
  margin: 0;
  font-family: NeueHaasUnica-Regular, Arial;
}

h1 {
  color: #969696;
  letter-spacing: .5px;
  margin: 0;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.68px;
}

p {
  color: #969696;
  letter-spacing: .3px;
  text-transform: uppercase;
  margin: 0;
  padding: 1rem 0;
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 1.15;
}

@media only screen and (min-width: 992px) {
  p {
    text-transform: none;
    padding: 30px 0 0;
    font-size: 25px;
  }
}

p span {
  font-size: 4.5rem;
}

a {
  color: #969696;
  letter-spacing: .3px;
  text-transform: uppercase;
  border-bottom: 2px solid;
  padding-bottom: 2px;
  font-size: 2.75rem;
  font-weight: 400;
  line-height: 1.15;
  text-decoration: none;
}

a:hover {
  color: #000;
}

@media only screen and (min-width: 992px) {
  button, a {
    color: #000;
    text-transform: none;
    letter-spacing: .3px;
    background-color: #0000;
    border: none;
    border-bottom: 1px solid;
    outline: none;
    padding: 30px 0 0;
    font-size: .75rem;
    font-weight: 400;
    line-height: 1.15;
    display: inline-block;
  }

  button:hover, a:hover {
    cursor: pointer;
  }
}

.textSmall {
  text-transform: none;
  font-size: 1.5rem;
}

header {
  box-sizing: border-box;
  z-index: 2;
  background: #fff;
  width: 100%;
  height: 100%;
  padding: 14rem 4rem 4rem;
  transition: all .5s ease-out;
  position: fixed;
  top: 0;
  left: 100%;
  overflow: scroll;
}

header.active {
  left: 0;
}

header.active:hover {
  cursor: default;
}

nav {
  z-index: 10;
  display: block;
  position: fixed;
  top: 4rem;
  right: 4rem;
}

@media only screen and (min-width: 992px) {
  nav {
    display: none;
  }
}

nav button {
  text-align: center;
  color: #000;
  background: #bababa;
  border: none;
  border-radius: 20rem;
  outline: none;
  width: 100%;
  min-width: 140px;
  margin: 0;
  padding: .8rem 2rem 1.4rem;
  font-family: Times New Roman, serif;
  font-size: 2.75rem;
  line-height: 1;
  display: inline-block;
  box-shadow: inset -10px -10px 10px #0000004d, inset 10px 10px 10px #ffffff4d;
}

nav button:active, nav button:focus {
  outline: none;
}

.canvasWrapper {
  opacity: 1;
  width: 100%;
  height: 100%;
  position: relative;
}

canvas {
  z-index: 0;
  transform-origin: center;
  background-size: contain;
  transition: all 1s;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow-y: hidden;
  transform: translate(-50%, -50%)scale(0);
}

section {
  transform-origin: center;
  background: #fff;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  overflow: hidden;
}

.desktop {
  display: none;
}

@media only screen and (min-width: 992px) {
  .desktop {
    padding: 30px;
    display: block;
  }
}

.desktop article {
  grid-template-columns: 4fr 1fr;
  place-items: flex-start end;
  max-width: 487px;
  display: grid;
}

.desktop .qrCode {
  opacity: 0;
  align-self: end;
  width: 70px;
  padding-left: 40px;
  transition: all .25s;
  transform: translateY(20px);
}

.desktop .qrCode.active {
  opacity: 1;
  transform: translateY(0);
}

mux-player::part(gesture-layer) {
  display: none;
}

mux-player {
  --controls: none;
  --media-object-fit: cover;
  aspect-ratio: 215 / 132;
  width: 100%;
  height: auto;
}

.desktop mux-player {
  width: 430px;
  height: auto;
  padding-top: 30px;
}

footer {
  gap: 40px;
  width: 100%;
  max-width: 487px;
  display: flex;
}

@keyframes splash {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.animateOnce {
  animation: 1s ease-in-out forwards splash;
}

/*# sourceMappingURL=index.5a3fd7ba.css.map */
