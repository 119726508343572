/*
  Custom Styles
*/
@font-face {
  font-family: "NeueHaasUnica-Regular";
  src: local("NeueHaasUnica-Regular"),
    url("assets/NeueHaasUnica-Regular.woff") format("woff");
}

::selection {
  color: white;
  background: black;
}

body {
  height: 100vh;
  height: 100svh;
  background: white;
  margin: 0;

  font-family: NeueHaasUnica-Regular, Arial;
  -webkit-font-smoothing: antialiased;
}

h1 {
  color: #969696;
  font-size: 12px;
  font-weight: 400;
  line-height: 13.68px;
  letter-spacing: 0.5px;
  margin: 0;
}

p {
  padding: 1rem 0;
  margin: 0;
  color: #969696;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: 0.3px;

  font-size: 2.6rem;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) {
  p {
    padding: 30px 0 0 0;
    font-size: 25px;
    text-transform: none;
  }
}

p span {
  font-size: 4.5rem;
}

a {
  color: #969696;
  font-weight: 400;
  line-height: 1.15;
  letter-spacing: 0.3px;
  text-decoration: none;
  padding-bottom: 2px;

  font-size: 2.75rem;
  text-transform: uppercase;
  border-bottom: 2px solid;
}

a:hover {
  color: #000;
}

@media only screen and (min-width: 992px) {
  button,
  a {
    color: black;
    outline: none;
    display: inline-block;
    background-color: transparent;
    border: none;
    font-size: 0.75rem;
    text-transform: none;

    font-weight: 400;
    line-height: 1.15;
    letter-spacing: 0.3px;
    padding: 30px 0 0 0;
    border-bottom: 1px solid;
  }

  button:hover,
  a:hover {
    cursor: pointer;
  }
}

.textSmall {
  font-size: 1.5rem;

  text-transform: none;
}

header {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  overflow: scroll;

  box-sizing: border-box;
  padding: 14rem 4rem 4rem 4rem;

  z-index: 2;
  background: white;

  transition: all 0.5s ease-out;
}

header.active {
  left: 0;
}

header.active:hover {
  cursor: default;
}

nav {
  display: block;
  position: fixed;
  top: 4rem;
  right: 4rem;

  z-index: 10;
}

@media only screen and (min-width: 992px) {
  nav {
    display: none;
  }
}

nav button {
  border: none;
  font-size: 2.75rem;
  line-height: 1;
  width: 100%;
  min-width: 140px;
  display: inline-block;
  text-align: center;
  padding: 0.8rem 2rem 1.4rem 2rem;
  margin: 0;
  outline: none;
  color: black;
  border-radius: 20rem;
  background: #bababa;
  box-shadow: -10px -10px 10px 0px rgba(0, 0, 0, 0.3) inset,
    10px 10px 10px 0px rgba(255, 255, 255, 0.3) inset;
  font-family: "Times New Roman", serif;
}

nav button:active,
nav button:focus {
  outline: none;
}

.canvasWrapper {
  position: relative;
  opacity: 1;
  width: 100%;
  height: 100%;
}

canvas {
  overflow-y: hidden;
  position: absolute;
  background-size: contain;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 0;
  display: block;
  transform-origin: center;

  transition: all 1s;
}

section {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  display: block;
  background: white;

  transform-origin: center;
}

.desktop {
  display: none;
}

@media only screen and (min-width: 992px) {
  .desktop {
    display: block;
    padding: 30px;
  }
}

.desktop article {
  max-width: 487px;
  display: grid;
  grid-template-columns: 4fr 1fr;
  align-items: flex-start;
  justify-items: end;
}

.desktop .qrCode {
  align-self: end;
  width: 70px;
  padding-left: 40px;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.25s;
}

.desktop .qrCode.active {
  opacity: 1;
  transform: translateY(0);
}

mux-player::part(gesture-layer) {
  display: none;
}

mux-player {
  --controls: none;
  --media-object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 215 / 132;
}

.desktop mux-player {
  width: 430px;
  height: auto;
  padding-top: 30px;
}

footer {
  display: flex;
  gap: 40px;
  width: 100%;
  max-width: 487px;
}

@keyframes splash {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.animateOnce {
  animation: splash 1s normal forwards ease-in-out;
}
